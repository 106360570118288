import { useEffect, useRef } from 'react';

interface TweetProps {
  id: string;
}

export function Tweet({ id }: TweetProps) {
  const tweetRef = useRef<HTMLDivElement>(null);
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    // Add Twitter widgets script if it doesn't exist
    if (!window.twttr) {
      scriptRef.current = document.createElement('script');
      scriptRef.current.setAttribute('src', 'https://platform.twitter.com/widgets.js');
      scriptRef.current.setAttribute('async', 'true');
      document.head.appendChild(scriptRef.current);
    }

    // Render tweet once script is loaded
    const renderTweet = () => {
      if (window.twttr && tweetRef.current) {
        // Clear previous content to prevent duplicates
        tweetRef.current.innerHTML = '';
        window.twttr.widgets.createTweet(id, tweetRef.current, {
          theme: 'light',
          width: '100%',
          align: 'center',
          conversation: 'none',
        });
      }
    };

    // Only render if the script is already loaded
    if (window.twttr) {
      renderTweet();
    } else {
      // Wait for script to load before rendering
      const onLoad = () => renderTweet();
      window.addEventListener('load', onLoad);
      return () => window.removeEventListener('load', onLoad);
    }

    // Cleanup function
    return () => {
      if (scriptRef.current) {
        document.head.removeChild(scriptRef.current);
        scriptRef.current = null;
      }
      if (tweetRef.current) {
        tweetRef.current.innerHTML = '';
      }
    };
  }, [id]); // Only re-run if tweet ID changes

  return <div ref={tweetRef} className="w-full" />;
}