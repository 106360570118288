import SectionHeading from "../layout/SectionHeading";
import { Card, CardContent } from "@/components/ui/card";

export default function About() {
  const experience = [
    {
      role: "Founder @ ",
      company: "Building Something New",
      companyUrl: "#",
      description:
        "Currently developing a new venture focused on modernizing trade businesses.",
    },
    {
      role: "CPTO @ ",
      company: "Zen Educate",
      companyUrl: "https://zeneducate.com",
      description:
        "Led product, design, and engineering teams to build a marketplace to connect supply teachers and schools.",
    },
    {
      role: "Director, Google Cloud Delivery @ ",
      company: "CTS (now Qodea)",
      companyUrl: "https://cts.co",
      description:
        "Ran the GCP Managed Services, Delivery Management, and the Programme Management Office departments.",
    },
    {
      role: "Group Product Manager - Platform @ ",
      company: "TransferWise (now Wise)",
      companyUrl: "https://wise.com",
      description:
        "Coordinated 12 teams to deliver AI and cloud platform services, including ML, data, and engineering experience.",
    },
    {
      role: "Head of Engineering - UK @ ",
      company: "Cloudreach (now Eviden)",
      companyUrl: "https://cloudreach.com",
      description:
        "Led a team of 80 developers and engineers, achieving 60% year-over-year business growth.",
    },
  ];

  return (
    <section id="about">
      <SectionHeading>About Me</SectionHeading>

      <div className="prose prose-slate max-w-none mb-8">
        <p>
          I previously built and scaled technology businesses, but I am now
          building companies on my terms—focusing on sustainable growth and
          creating lasting value. Entrepreneur with experience in leading
          product and engineering teams. I'm passionate about technology,
          continuous learning, and business building.
        </p>
      </div>

      <SectionHeading>Experience</SectionHeading>

      <div className="space-y-3">
        {experience.map((job, index) => (
          <Card key={index}>
            <CardContent className="p-3">
              <div className="flex flex-col gap-1">
                <h3 className="font-semibold">
                  {job.role}
                  <a
                    href={job.companyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#20B2AA] hover:text-[#148f89] transition-colors"
                  >
                    {job.company}
                  </a>
                </h3>
                <p className="text-sm text-muted-foreground">
                  {job.description}
                </p>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </section>
  );
}
