import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import About from "@/components/sections/About";
import Blog from "@/components/sections/Blog";
import Contact from "@/components/sections/Contact";
import Sidebar, { NewsletterSection, UpdatesSection } from "@/components/layout/Sidebar";
import { useIsMobile } from "@/hooks/use-mobile";

//getServerSideProps function is retained, although its functionality is now irrelevant without Next.js.
export const getServerSideProps = async () => {
  return {
    props: {
      // Add any data you want to pre-fetch here
    }
  };
};

export default function Home() {
  const isMobile = useIsMobile();
  const [isClient, setIsClient] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    setIsClient(true);
    setCurrentUrl(window.location.href);
  }, []);

  return (
    <>
      <Helmet>
        <title>Daniel Smythe - Father, Entrepreneur, Investor & Tech Guy</title>
        <meta name="description" content="Personal website of Daniel Smythe - Father, Entrepreneur, Investor and General Product and Tech Guy" />
        <meta name="keywords" content="Daniel Smythe, Entrepreneur, Investor, Technology, Product Management, Engineering Leadership" />
        <meta property="og:title" content="Daniel Smythe" />
        <meta property="og:description" content="Father, Entrepreneur, Investor & General Product and Tech Guy" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Daniel Smythe" />
        <meta name="twitter:description" content="Father, Entrepreneur, Investor & General Product and Tech Guy" />
        <link rel="canonical" href={currentUrl} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="min-h-screen bg-gray-50">
        <div className="container mx-auto px-4 py-8">
          {/* Mobile/Tablet Sidebar Content */}
          {isClient && (
            <div className={isMobile ? "mb-4" : "hidden"}>
              <div className="flex flex-col items-center">
                <Sidebar />
              </div>
            </div>
          )}

          <div className="flex gap-8">
            {/* Desktop Sidebar */}
            {isClient && (
              <aside className={!isMobile ? "sticky top-8 h-fit w-96" : "hidden"}>
                <Sidebar />
              </aside>
            )}

            {/* Main Content */}
            <div className="flex-1 max-w-3xl mx-auto lg:mx-0">
              <header className="mb-8 lg:mb-12 text-center lg:text-left">
                <h1 className="text-4xl font-bold mb-2">Daniel Smythe</h1>
                <p className="text-lg text-muted-foreground">
                  Father, Entrepreneur, Investor & General Product and Tech Guy
                </p>
              </header>

              <div className="space-y-20">
                <About />
                <Blog />
                
                {/* Mobile/Tablet Newsletter and Updates */}
                {isClient && isMobile && (
                  <div className="space-y-6 max-w-2xl mx-auto">
                    <NewsletterSection />
                    <UpdatesSection />
                  </div>
                )}

                <Contact />
              </div>

              <footer className="mt-20 py-8 text-center lg:text-left text-sm text-muted-foreground">
                <p>&copy; {new Date().getFullYear()} Daniel Smythe</p>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}