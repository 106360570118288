import SectionHeading from "../layout/SectionHeading";
import { Button } from "@/components/ui/button";
import { Mail } from "lucide-react";

export default function Contact() {
  return (
    <section id="contact">
      <SectionHeading>Get in Touch</SectionHeading>

      <div className="text-center">
        <Button variant="ghost" size="lg" className="mb-6" asChild>
          <a href="mailto:daniel@danielsmythe.com" className="flex items-center gap-2">
            <Mail className="h-5 w-4" />
            daniel@danielsmythe.com
          </a>
        </Button>
      </div>
    </section>
  );
}

// Sidebar component (preserved from original file)
const Sidebar = () => {
  return (
    <aside className="sidebar">
      {/* Placeholder for Newsletter subscription -  Add your existing newsletter code here */}
      <div className="social-icons">
        <a href="https://www.linkedin.com/in/danielsmythe/" target="_blank" rel="noopener noreferrer" className="text-teal-500 hover:text-teal-700">
          <i className="fab fa-linkedin"></i> LinkedIn
        </a>
        <a href="https://twitter.com/danielsmythe" target="_blank" rel="noopener noreferrer" className="text-teal-500 hover:text-teal-700">
          <i className="fab fa-twitter"></i> Twitter
        </a>
      </div>
    </aside>
  );
};

export {Sidebar};