import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { SiLinkedin, SiX } from "react-icons/si";
import { Mail } from "lucide-react";
import { Tweet } from "@/components/ui/tweet";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { useIsMobile } from "@/hooks/use-mobile";

export function NewsletterSection() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const isMobile = useIsMobile();

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('/api/newsletter/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }

      toast({
        title: "Success!",
        description: "You've been subscribed to the newsletter.",
      });

      setEmail("");
    } catch (error) {
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to subscribe",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className={isMobile ? "w-full md:max-w-2xl mx-auto" : ""}>
      <CardContent className="p-4">
        <h3 className="font-semibold mb-2">Subscribe to my newsletter</h3>
        <p className="text-sm text-muted-foreground mb-4">
          Get updates on my latest ventures and insights.
        </p>
        <form onSubmit={handleSubscribe} className="space-y-2">
          <Input
            type="email"
            placeholder="your@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full"
          />
          <Button className="w-full" type="submit" disabled={isLoading}>
            <Mail className="mr-2 h-4 w-4" />
            {isLoading ? "Subscribing..." : "Subscribe"}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

export function UpdatesSection() {
  const isMobile = useIsMobile();

  return (
    <Card className={`overflow-hidden ${isMobile ? "w-full md:max-w-2xl mx-auto" : ""}`}>
      <CardContent className="p-4">
        <div className="flex items-center gap-2 mb-4">
          <SiX className="h-5 w-5" />
          <h3 className="font-semibold">Latest Updates</h3>
        </div>
        <div className="space-y-4 w-full">
          <div className="!mt-0">
            <Tweet id="1878310780489134375" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function ProfileSection() {
  return (
    <>
      <div className="flex justify-center mb-2">
        <div className="w-32 h-32 md:w-36 md:h-36 lg:w-40 lg:h-40 rounded-full overflow-hidden border-4 border-white shadow-lg">
          <img
            src="/assets/profile.jpg"
            alt="Daniel Smythe"
            className="w-full h-full object-cover object-[center_20%]"
          />
        </div>
      </div>

      <div className="flex justify-center space-x-4 mt-2">
        <Button variant="ghost" size="icon" className="text-[#20B2AA] hover:text-[#148f89]" asChild>
          <a href="https://linkedin.com/in/danieljsmythe" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            <SiLinkedin className="h-5 w-5" />
          </a>
        </Button>
        <Button variant="ghost" size="icon" className="text-[#20B2AA] hover:text-[#148f89]" asChild>
          <a href="https://x.com/danieljsmythe" target="_blank" rel="noopener noreferrer" aria-label="X (formerly Twitter)">
            <SiX className="h-5 w-5" />
          </a>
        </Button>
      </div>
    </>
  );
}

export default function Sidebar({ className = "" }: { className?: string }) {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <div className={`w-full max-w-[288px] md:max-w-[320px] space-y-6 ${className}`}>
        <ProfileSection />
      </div>
    );
  }

  return (
    <div className={`w-72 space-y-6 ${className}`}>
      <ProfileSection />
      <NewsletterSection />
      <UpdatesSection />
    </div>
  );
}