import { Route, Switch } from "wouter";
import { Suspense } from "react";
import Home from "@/pages/Home";
import BlogPost from "@/pages/BlogPost";
import { Toaster } from "@/components/ui/toaster";

function ErrorBoundary({ error }: { error: Error }) {
  return (
    <div className="p-4">
      <h1>Oops! Something went wrong.</h1>
      <p>{error.message}</p>
    </div>
  );
}

export default function App() {
  return (
    <>
      <Suspense fallback={<div className="p-4">Loading app...</div>}>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
          <Route path="/blog/:slug">
            <BlogPost />
          </Route>
        </Switch>
      </Suspense>
      <Toaster />
    </>
  );
}
