import { useState, useEffect } from "react";
import SectionHeading from "../layout/SectionHeading";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowUpRight } from "lucide-react";
import { Link } from "wouter";
import { SearchInput } from "@/components/ui/search-input";
import { useDebounce } from "@/hooks/use-debounce";
import { Skeleton } from "@/components/ui/skeleton";
import type { Post } from "@/lib/posts";

function BlogPostSkeleton() {
  return (
    <Card>
      <CardContent className="p-4">
        <div className="space-y-3">
          <Skeleton className="h-6 w-3/4" />
          <Skeleton className="h-4 w-1/4" />
          <div className="space-y-2">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-5/6" />
          </div>
          <Skeleton className="h-9 w-24" />
        </div>
      </CardContent>
    </Card>
  );
}

export default function Blog() {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [posts, setPosts] = useState<Post[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const debouncedSearch = useDebounce(searchQuery, 300);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('/api/posts');
        if (!response.ok) {
          throw new Error(`Failed to fetch posts: ${response.statusText}`);
        }
        const allPosts = await response.json();
        
        // Filter posts based on search
        const filteredPosts = debouncedSearch
          ? allPosts.filter((post: Post) =>
              post.title.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
              post.content.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
              post.excerpt.toLowerCase().includes(debouncedSearch.toLowerCase())
            )
          : allPosts;

        // Paginate posts
        const POSTS_PER_PAGE = 2;
        const start = (page - 1) * POSTS_PER_PAGE;
        const end = start + POSTS_PER_PAGE;
        
        setPosts(filteredPosts.slice(start, end));
        setHasMore(end < filteredPosts.length);
        setError(null);
      } catch (err) {
        console.error('Error fetching posts:', err);
        setError(err instanceof Error ? err : new Error('Failed to fetch posts'));
        setPosts([]);
        setHasMore(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [page, debouncedSearch]);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    setPage(1);
  };

  const handleLoadMore = () => {
    setPage(prev => prev + 1);
  };

  if (error) {
    return (
      <section id="blog">
        <SectionHeading>Blog Articles</SectionHeading>
        <p className="text-center text-red-500">Failed to load blog posts. Please try again later.</p>
      </section>
    );
  }

  return (
    <section id="blog">
      <SectionHeading>Blog Articles</SectionHeading>

      <div className="mb-6">
        <SearchInput
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search blog posts..."
        />
      </div>

      {isLoading ? (
        <div className="grid gap-4">
          {[1, 2].map((index) => (
            <BlogPostSkeleton key={index} />
          ))}
        </div>
      ) : posts.length === 0 ? (
        <p className="text-center text-muted-foreground">
          {debouncedSearch ? "No posts found matching your search." : "No blog posts available."}
        </p>
      ) : (
        <div className="grid gap-4">
          {posts.map((post: Post) => (
            <Card key={post.slug}>
              <CardContent className="p-4">
                <h3 className="font-semibold text-lg mb-2">{post.title}</h3>
                <time className="text-sm text-muted-foreground block mb-2">
                  {new Date(post.date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </time>
                <p className="text-muted-foreground mb-4">{post.excerpt}</p>
                <Link href={`/blog/${post.slug}`}>
                  <Button variant="ghost" className="group">
                    Read More
                    <ArrowUpRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
                  </Button>
                </Link>
              </CardContent>
            </Card>
          ))}
        </div>
      )}

      {hasMore && !isLoading && (
        <div className="mt-6 text-center">
          <Button onClick={handleLoadMore} variant="outline">
            Load More Posts
          </Button>
        </div>
      )}
    </section>
  );
}